.root {
  width: 100vw;
}

@media screen and (max-width: 400px) {
  #features {
    padding: 10px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
  .section-title h2 {
    font-size: 30px;
  }
}